.ql-mention-list-container {
  color: #F58937;
  background-color: transparent;
  font-family: 'Equip_Regular';
  border-radius: 0.5rem;
  width: 14.065rem !important; 
  min-width: 112px;
  max-height: 112px;

  .ql-mention-list {
    border-radius: 0.5rem;
    overflow: scroll;
    .ql-mention-list-item {
      color: #89736D;
      line-height: 2.125rem;
      padding: 0rem .75rem;
      font-size: .75rem;
      font-family: 'Equip_Regular';
      font-weight: 400;
      border-top: 1px solid #E2E2E2;
  }
  .ql-mention-list-item.selected {
    background: hoverPaleOrange;
    color: #3E2723;
}

.ql-mention-list-item:first-of-type{
  border-top: none; 
}
} 


}