@font-face {
  font-family: 'Equip_Regular';
  src: url('../fonts/Equip_OTF/EquipReg.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Equip_Bold';
  src: url('../fonts/Equip_OTF/EquipBol.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Equip_Extended';
  src: url('../fonts/Equip_OTF/EquipExtReg.otf');
  font-display: swap;
}

@font-face {
  font-family: 'Equip_Extended_Bold';
  src: url('../fonts/Equip_OTF/EquipExtBol.otf');
  font-display: swap;
}

#__next {
  font-family: 'Equip_Regular';
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  margin-top: .15em;
  box-shadow: inset 0 0 5px #fff;
  border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f58937;
  border-radius: 6px;
}


.custom-scroll-bar:hover {
  div:last-child {
    // opacity: 1 !important;
  }
}

// /* Chrome, Safari, Edge, Opera */
// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }


// /* Firefox */
// input[type="number"] {
//   -moz-appearance: textfield;
// }
.apexcharts-menu-item.exportSVG {
  display: none;
}

ul[role="listbox"]{
  padding: 0;
}
.report_dropzone{
  border-bottom: 1px solid #E2E2E2;
  padding-top: 10px;
  padding-bottom: 10px ;
}

.recharts-sector,
.recharts-layer {
  outline: none;
}

.chart_labels_edit{
  font-family: 'Equip_Bold';
  font-size: 12px;
}
.nudge-configure-name-remaining-char{
  line-height:1;
  margin-left:0rem !important;
}
